#print_options_dialog {
  label {
    display: block;
    float: right;
    margin-bottom: 1.3em;
    width: 85%;
  }

  input {
    float: left;
  }
}

#prop_notes {
  font-size: 1.2em;
}

.save-proposal-notes {
  float: right;
  margin-right: 8rem;
  margin-top: -4.1em;
}

.print-proposals {
  float: right;
  margin-top: -4em;
}

.quote-container {
  .dt-buttons {
    float: right;
  }

  .generate-quotes {
    float: right;
    margin-top: 5px;
  }
}

.form-overlay {
  background: rgba(238, 238, 238, 0.84);
  height: 100%;
  padding: 0;
  width: 100%;

  @media screen and (min-width: $breakpoint-large) {
    padding: 13em 75em;
  }

  .btn-cancel {
    float: left;
    margin-right: 1em;
  }

  #proposal_print_form_submit {
    margin-bottom: 2em;
  }

  #proposal_print_form {
    width: 40em;
  }

  input {
    margin: 1em 2em;
  }

  label {
    clear: right;
    margin-right: 17em;
  }

  form {
    @extend .DTE_Body;

    background: white;
    border: 1px solid $black;
    left: calc(50% - 225px);
    height: 450px;
    padding: 1em;
    position: absolute;
    top: 225px;
    width: 450px;
  }
}

#proposal_print_form_proposals_chosen {
  width: 385px !important;
}

.proposal-grid {
  margin-bottom: 2em;

  .DTE_Action_Create {
    padding: 6em 58em 0;
    width: 55em;
  }

  .ui-datepicker-trigger {
    display: none;
  }

  #proposal_form {
    .DTE_Field {
      clear: both;
      width: 92%;
    }

    input {
      margin: .5em 0 0;
      width: 98%;
    }
  }
}

.proposal-print-form {

  .form-row {
    label {
      margin-right: 0;
    }
  }

  .edit-print-options-link {
    margin-left: -85px;
    position: absolute;
  }

  #proposal_print_form_proposals_chosen {
    .chosen-choices {
      max-height: 11em;
      overflow-y: scroll;
    }
  }
}
